import React from "react";

export default function IncomingPopup(props) {
  let { declineText, acceptText, calllerName, title } = props.props.detail;
  const startLetter = calllerName ? calllerName.split("")[0] : "V";

  return (
    <section className="ph-incomingcall open-window">
      <div className="ph-incomingcall-container ch-support">
        <figure className="ph-incomingcall__bg">
          <img
            src="https://lh3.googleusercontent.com/iuWTAnI5amwmhrLpzAMh5e7kkZl_zXAZgaYN113rHrRJLMd4zHcqASiw6OEddIxi9OeajQzP4Q0r4meJXVw3GyElcyUQ?aspectRatio=1.0"
            alt="Incoming call bg"
          />
        </figure>
        <div className="caller-group">
          <figure className="caller-avatar">
            <figcaption>{startLetter}</figcaption>
          </figure>
          <div className="caller-info">
            <h6>{calllerName}</h6>
            <span>{title}</span>
          </div>
        </div>
        <ul className="call-options">
          <li>
            <button
              className="nostyle end-btn"
              onClick={() => props.props.detail.declineIncomingEvent()}
            >
              {props.props.detail.declineIcon()}
            </button>
            <span>{declineText}</span>
          </li>
          <li>
            <button
              className="accept-btn"
              onClick={() => props.props.detail.acceptIncomingEvent()}
            >
              {props.props.detail.acceptIcon()}
            </button>
            <span>{acceptText}</span>
          </li>
        </ul>
      </div>
    </section>
  );
}
