/* eslint-disable require-yield */
import { AxiosResponse } from 'axios'
import { takeLatest, call, put } from 'redux-saga/effects'
import { config } from 'src/config'
import { postAccount, postSessionStep3 } from 'src/features/authorization/api'
import {
  ISignUpResponse,
  EnumPostAccountErrors,
} from 'src/features/authorization/api/types'
import * as actions from 'src/features/authorization/store/actions'
import { history } from 'src/history'
import { SESSION_STEP_2_ROUTE } from 'src/pages/SessionStep2/data'
import { SESSION_STEP_3_ROUTE } from 'src/pages/SessionStep3/data'
import { SIGN_UP_SUCCESS_ROUTE } from 'src/pages/SignUpSuccess/data'
import { mapExceptionMessage } from 'src/utils/api/mapper/mapExceptionMessage'
declare let window: any

const storeAccountID = (accountID: string) => {
  localStorage.setItem('accountId', accountID)
}

const storeLogin = (login: string) => {
  localStorage.setItem('login', login)
}

const storeUserId = (userId: string) => {
  localStorage.setItem('ls_user_id', userId)
}

export function* sagaRequestSignUp(
  action: ReturnType<typeof actions.requestSignUp>
) {
  try {
    const {
      data: {
        data: {
          ssotoken: ssoToken,
          contact: { id: userId },
          widget: { id: widgetId },
          accountID,
          login,
        },
      },
    }: AxiosResponse<ISignUpResponse> = yield call(postAccount, action.payload)
    storeAccountID(accountID)
    storeLogin(login)
    storeUserId(userId)
    yield put(actions.requestSignUpSuccess({ widgetId, userId, ssoToken }))
  } catch (error) {
    const errorMessage = mapExceptionMessage(
      EnumPostAccountErrors,
      error?.Exception
    )
    yield put(actions.setError(errorMessage))
  }
}

export function* sagaRegisterSessionStep1(
  action: ReturnType<typeof actions.requestSignUpSuccess>
) {
  const redirectUrl = `${config.chatsupportAppUrl}${SESSION_STEP_2_ROUTE}`
  window.location.href = `${config.authApiUrl}/o/sso/${action.payload.ssoToken}?continue=${redirectUrl}`
}

export function* sagaRegisterSessionStep2(): any {
  const redirectUrl = `${config.chatsupportAppUrl}${SESSION_STEP_3_ROUTE}`
  window.location.href = `${config.authApiUrl}/o/oauth2/auth?response_type=code&client_id=${config.authClientId}&scope=contacts-api.full_access%20awapis.users.invite%20awapis.account.create&project=${config.authProject}&access_type=OFFLINE&redirect_uri=${redirectUrl}`
}

export function* sagaRegisterSessionStep3(
  action: ReturnType<typeof actions.requestSessionStep3>
): any {
  try {
    yield call(postSessionStep3, action.payload.code)
    history.push(SIGN_UP_SUCCESS_ROUTE)
  } catch (error) {
    const errorMessage = mapExceptionMessage(
      EnumPostAccountErrors,
      error?.Exception
    )
    yield put(actions.setError(errorMessage))
  }
}

/* istanbul ignore next */
export function* authorizationSagas() {
  yield takeLatest(actions.REQUEST_SIGN_UP, sagaRequestSignUp)
  yield takeLatest(actions.REQUEST_SIGN_UP_SUCCESS, sagaRegisterSessionStep1)
  yield takeLatest(actions.REQUEST_SESSION_STEP_2, sagaRegisterSessionStep2)
  yield takeLatest(actions.REQUEST_SESSION_STEP_3, sagaRegisterSessionStep3)
}
