import {
  PROJECTLIST_REQUESTED,
  PROJECTLIST_RESPONSE,
  PROJECTLIST_FAILED,
  NEW_PROJECTLIST_REQUESTED,
  NEW_PROJECTLIST_RESPONSE,
  DELETEPROJECT_REQUESTED,
  DELETEPROJECT_RESPONSE,
  DELETEPROJECT_FAILED,
  UPDATE_REMOVED_PROJECTS,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_RESPONSE,
  UPDATE_PROJECT_FAILED,
  UPDATE_UNASSIGNED_CONVERSATION_COUNT,
  UPDATE_UNASSIGNED_CONVERSATION_COUNT_REALTIME,
  UPDATE_PROJECT_LOGO_REQUEST,
  UPDATE_PROJECT_LOGO_RESPONSE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_RESPONSE,
  CLEAR_CREATED_PROJECTKEY,
  CREATE_PROJECT_FAILED,
  SHOW_NEW_PROJECT_POPUP,
  HIDE_NEW_PROJECT_POPUP,
  PROJECT_REQUESTED,
} from "../actions/ProjectAction";
import { MESSAGE_TYPE } from "../../commons/Constants";
import {
  incrementUnassignedCount,
  decrementUnassignedCount,
  incrementUassignedCount,
} from "../../commons/Utility";

export const initialState = {
  projectList: [],
  isFetching: false,
  isFetched: false,
  isUpdating: false,
  isUpdated: false,
  showNewProjectPopup: false,
  isAllowedToCreate: true,
  createdProject: "",
};

const ProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECTLIST_REQUESTED:
    case PROJECT_REQUESTED:
      return {
        ...state,
        isFetching: true,
        projectList: [],
        isFetched: false,
      };
    case PROJECTLIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        projectList: action.projectList,
        isAllowedToCreate: action.isAllowedToCreate,
      };
    case PROJECTLIST_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        projectList: [],
      };
    case NEW_PROJECTLIST_REQUESTED:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };
    case NEW_PROJECTLIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        projectList: state.projectList.concat(action.projectList),
        isAllowedToCreate: action.isAllowedToCreate,
      };
    case UPDATE_REMOVED_PROJECTS:
      let updatedList = state.projectList.filter((project) => {
        return !action.projectList.includes(project.key);
      });
      console.info("updatedList:" + updatedList);
      return {
        ...state,
        projectList: updatedList,
      };
    case DELETEPROJECT_RESPONSE:
      return {
        ...state,
        projectList: state.projectList.filter(
          (project) => project.key != action.projectKey
        ),
      };
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
      };
    case CREATE_PROJECT_RESPONSE:
      return {
        ...state,
        createdProject: action.project.key,
        projectList: state.projectList.concat([action.project]),
      };
    case CLEAR_CREATED_PROJECTKEY:
      return {
        ...state,
        createdProject: "",
      };
    case CREATE_PROJECT_FAILED:
      return {
        ...state,
      };
    case UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_PROJECT_RESPONSE:
      let projectList = state.projectList.filter(
        (project) => project.key != action.project.key
      );
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        projectList: projectList.concat([action.project]), // This function need to be  updated
      };
    case UPDATE_UNASSIGNED_CONVERSATION_COUNT:
      let projects = state.projectList.filter(
        (project) => project.key != action.project.projectKey
      );
      let project = state.projectList.filter(
        (project) => project.key == action.project.projectKey
      )[0];
      if (project) {
        project.unAssignedConversationCount =
          action.project.unassignedConversationCount;
      }
      return {
        ...state,
        projectList: project ? projects.concat([project]) : projects,
      };

    case UPDATE_UNASSIGNED_CONVERSATION_COUNT_REALTIME:
      let message = action.message;
      let conversation = action.conversation
      let allProjects = state.projectList.filter(
        (project) => project.key != message.projectId
      );
      let _project = state.projectList.filter(
        (project) => project.key == message.projectId
      )[0];

      switch (message.type) {
        case MESSAGE_TYPE.chat_auto_assigned_on_reply:
          decrementUnassignedCount(_project);
          break;
        case MESSAGE_TYPE.chat_closed:
          if (conversation && !conversation.assignedTo)
            decrementUnassignedCount(_project);
          break;
        case MESSAGE_TYPE.chat_reopen:
          if (conversation && !conversation.assignedTo) {
            let userId = message.actionTo
              ? message.actionTo
              : conversation.assignedTo;
            if (!userId) incrementUnassignedCount(_project);
          }
          break;
        case MESSAGE_TYPE.chat_unassigned:
          incrementUnassignedCount(_project);
          break;
        case MESSAGE_TYPE.chat_assigned:
        case MESSAGE_TYPE.chat_assigned_on_connect:
          if (conversation && conversation.chatStatus === "OPEN") {
            if (!conversation.assignedTo) decrementUnassignedCount(_project);
          }
          break;
        case MESSAGE_TYPE.chat_assign_and_reopen:
          if (conversation && conversation.chatStatus === "CLOSED") {
            if (!conversation.assignedTo) decrementUnassignedCount(_project);
          }
          break;
        case MESSAGE_TYPE.chat_auto_assigned_available:
          decrementUnassignedCount(_project);
          break;
        default:
          if (
            message.firstMessageOfConv &&
            message.messageFrom &&
            message.messageFrom != "AGENT"
          )
            incrementUnassignedCount(_project);
      }
      return {
        ...state,
        projectList: _project ? allProjects.concat([_project]) : allProjects,
      };
    case UPDATE_PROJECT_FAILED:
      return {
        isUpdating: false,
        ...state,
      };
    case SHOW_NEW_PROJECT_POPUP:
      return {
        ...state,
        showNewProjectPopup: true,
      };
    case HIDE_NEW_PROJECT_POPUP:
      return {
        ...state,
        showNewProjectPopup: false,
      };
    case UPDATE_PROJECT_LOGO_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
  }
  return state;
};

export default ProjectReducer;
